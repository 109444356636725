import React, { useRef } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import PreviousIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(() => ({
  root: {
    '& input': {
      textAlign: 'center',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
  },
}));

const NumberInput = ({ InputProps, inputRef, className, ...props }) => {
  const ref = useRef(inputRef);
  const classes = useStyles();

  const handleStepDown = (event) => {
    ref.current.stepDown();
    triggerChange();
  };
  const handleStepUp = (event) => {
    ref.current.stepUp();
    triggerChange();
  };

  const triggerChange = () => {
    ref.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  let classNames = [classes.root, className].join(' ');

  return (
    <TextField
      type="number"
      inputRef={ref}
      className={classNames}
      InputProps={{
        startAdornment: (
          <InputAdornment disableTypography={true} onClick={handleStepDown}>
            <PreviousIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment disableTypography={true} onClick={handleStepUp}>
            <NextIcon />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...props}
    />
  );
};

export default NumberInput;
