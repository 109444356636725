import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import 'src/assets/base.scss';

import App from './components/App';
import makeTheme from './theme';
import * as serviceWorker from './serviceWorker';

import AuthProvider from './components/AuthProvider';

const info = {
  date: process.env.REACT_APP_DATE,
  version: process.env.REACT_APP_VERSION,
  commit: process.env.REACT_APP_COMMIT,
  build: process.env.REACT_APP_BUILD_NUM,
};

const app = (window.app = {});
Object.entries(info).forEach(([key, value]) => {
  if (value) app[key] = value;
});

const Root = () => {
  const [theme, setTheme] = useState(
    window.localStorage.getItem('theme') || 'light',
  );

  const toggleTheme = useCallback(() => {
    const selection = theme === 'light' ? 'dark' : 'light';
    window.localStorage.setItem('theme', selection);
    setTheme(selection);
  }, [theme]);

  useEffect(() => {
    window.addEventListener('onThemeToggle', toggleTheme);
    return () => window.removeEventListener('onThemeToggle', toggleTheme);
  }, [toggleTheme]);

  return (
    <Router>
      <AuthProvider>
        <ThemeProvider theme={makeTheme(theme)}>
          <SnackbarProvider maxSnack={1}>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
};

const mountNode = document.getElementById('root');

ReactDOM.render(<Root />, mountNode);

serviceWorker.unregister();
