import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent } from '@material-ui/core';

import LoadingState from 'src/components/LoadingState';
import Logo from 'src/components/Page/Sidebar/Logo';
import AppStatus from 'src/components/AppStatus';
import useAuth from 'src/hooks/useAuth';
import LoginForm from './LoginForm';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: theme.spacing(60),
    maxWidth: '100%',
    backgroundColor: 'transparent',
  },
  content: {
    padding: theme.spacing(4),
    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));

const LoginView = (props) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(!window.isElectron);
  const { login, loading, error, isAuthenticated } = useAuth();

  const handleSubmit = async (data, actions) => {
    login({
      username: data.username,
      password: data.password,
    });
  };

  if (loading) return <LoadingState />;

  if (isAuthenticated) return <Redirect to="/dashboard" />;

  return (
    <div className={classes.root}>
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.content}>
          {isOpen ? (
            <>
              <Logo className={classes.title} />
              <LoginForm onSubmit={handleSubmit} {...props} error={error} />
            </>
          ) : (
            <AppStatus onLoginRequest={() => setOpen(true)} />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginView;
