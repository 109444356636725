import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import api from 'src/services/api';

const StoreSelector = ({
  defaultValue,
  selected,
  TextFieldProps,
  onChange,
  customerId,
  ...props
}) => {
  const [stores, setStores] = useState([]);

  useEffect(() => {
    const list = async () => {
      const storeList = await api.customers.stores(customerId);
      setStores(storeList);
    };
    list();
  }, [customerId, selected]);

  const filterOptions = (items, { inputValue }) =>
    items.filter(
      (item) =>
        (item.storeName?.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.storenum?.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.address1?.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.address2?.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.address3?.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.city?.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.state?.toLowerCase().includes(inputValue.toLowerCase())) &&
        selected?.id !== item.id,
    );

  const getOptionLabel = (item) => {
    if (!item) return '';
    const suffix = item.storenum ? `(#${item.storenum})` : '';
    return `${item.storeName} ${suffix}`;
  };

  const getOptionSelected = (option, value) => option.id === value.id;

  const handleChange = (_, item) => onChange(item);

  return (
    <Autocomplete
      id="store-selector"
      options={stores}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderOption={(option) =>
        option && (
          <div className="d-flex flex-column align-items-start">
            <p className="mb-0">
              {option.storeName} (#{option.storenum})
            </p>
            <p className="text-sm">
              <small>
                {[option.address1, option.city, option.state].join(', ')}
              </small>
            </p>
          </div>
        )
      }
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Store"
          placeholder="Store Number/Name/Address"
          variant="outlined"
          {...TextFieldProps}
        />
      )}
      {...props}
    />
  );
};

export default StoreSelector;
