import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@material-ui/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { primary, secondary, bookmarks } from './items';
import Logo from './Logo';

const Root = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  position: 'fixed',
  top: '0',
  left: '0px',
  overflowY: 'auto',
  overflowX: 'hidden',
  backgroundColor: 'inherit',
  borderRight: `1px solid ${theme.palette.divider}`,
  WebkitUserSelect: 'none',
  userSelect: 'none',
  zIndex: '1200',
}));

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '65px',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const List = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: '8px 0',
});

const ListItem = styled('li')({
  position: 'relative',
  paddingLeft: 8,
  paddingRight: 8,
});

const Content = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'space-between',
});

const Sidebar = () => {
  const renderItem = (item, index) => {
    const { icon, label, url } = item;
    const props = url ? { component: Link, to: url } : null;

    return (
      <ListItem key={index}>
        <Tooltip title={label} placement="right">
          <IconButton {...props} aria-label={label}>
            {icon}
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  };

  return (
    <Root>
      <Header>
        <Logo style={{ filter: 'saturate(0)', padding: '0 5px' }} width="64" />
      </Header>
      <Content>
        <List>{primary.map(renderItem)}</List>
        <List>{bookmarks.map(renderItem)}</List>
        <List>{secondary.map(renderItem)}</List>
      </Content>
    </Root>
  );
};

export default Sidebar;
