import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Grid,
  Button,
  TextField,
  FormControl,
  MenuItem,
  Typography,
  DialogActions,
} from '@material-ui/core';
import uuid from 'uuid';
import { makeStyles } from '@material-ui/styles';
import {
  LockOpen as UnlockedIcon,
  Lock as LockedIcon,
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import { default as SoftTextField } from 'src/components/TextField';

import api from 'src/services/api';
import ScannedItems from './ScannedItems';
import useSnackbar from 'src/hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
  formControlSm: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 120,
  },
  paper: {
    height: 'auto',
    padding: '5px',
    marginTop: theme.spacing(1),
  },
  keyboard: {
    border: `1px solid ${theme.palette.divider} !important`,
    background: theme.palette.background.default,
    '& .hg-button': {
      height: 60,
      padding: 0,
      fontSize: '1rem',
      fontWeight: 'bold',
      background: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  dispositionDisplay: {
    lineHeight: 1,
    fontSize: '7rem',
  },
  stampDisplay: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    pointerEvents: 'none',
    background: theme.palette.background.paper,
  },
}));

// FIXME: not so good.

const damageCodes = ['REGULAR', 'EMPTY ', 'TRASH', 'HAZMAT'];

const stamps = [
  {
    id: 2,
    description: 'NY(Newyork)',
  },
  {
    id: 3,
    description: 'KY(Kentucky)',
  },
];

let defaultValues = {
  quality: '',
  quantity: 1,
  upc: '',
  stamp: '',
};

// workaround for loading audio
// https://github.com/electron-react-boilerplate/electron-react-boilerplate/issues/1595
const beep = document.getElementById('audio-beep');
const doubleBeep = document.getElementById('audio-double-beep');
const errorTone = document.getElementById('audio-error');
const voiceEmptyBox = document.getElementById('audio-emptybox');
const voiceShiner = document.getElementById('audio-shiner');
const voiceTrash = document.getElementById('audio-trash');

// TODO: this component does a lot of things, probably should split
const ScanViewForm = ({ onCustomerChange, onReceivePallet, customer }) => {
  // setting initial quality option to regular
  const [formData, setFormData] = useState({
    ...defaultValues,
    quality: damageCodes[0],
  });

  const [payload, setPayload] = useState([]);
  const [sort, setSort] = useState(null);
  const [locks, setLocks] = useState({ quantity: false, stamp: false });
  const [isHazmatDialogOpen, setHazmatDialogOpen] = useState(false);

  const { showSnackbar } = useSnackbar();

  const barcodeInputRef = useRef();

  const classes = useStyles();

  const showError = useCallback(
    (message) => {
      errorTone.play();
      return showSnackbar({
        variant: 'error',
        message,
      });
    },
    [showSnackbar],
  );

  const findSortByDamage = useCallback((damage) => {
    switch (damage) {
      case 'TRASH':
      case 'EMPTY':
        return 'SALVAGE';
      case 'HAZMAT':
        return 'HAZMAT-H1';
      default:
        return 'NOF';
    }
  }, []);

  const updateSort = useCallback((selectedSort) => {
    setSort(selectedSort);
  }, []);

  const handleDamageChange = useCallback(
    (selectedDamage) => ({ info } = {}) => {
      const copyOfPayload = [...payload];
      const item = copyOfPayload.shift();
      if (!item || item.sort === 'SHINER') {
        return showError(
          'Please scan an item before trying to change the disposition.',
        );
      }

      if (selectedDamage === 'HAZMAT-REQ') {
        return setHazmatDialogOpen(true);
      }

      item.quality = selectedDamage;

      const sortFound = findSortByDamage(selectedDamage);
      updateSort(sortFound);
      item.sort = sortFound;

      item.info = info;

      setPayload([item, ...copyOfPayload]);
      switch (selectedDamage) {
        case 'TRASH':
          voiceTrash.play();
          break;
        case 'EMPTY':
          voiceEmptyBox.play();
          break;
        default:
          doubleBeep.play();
          break;
      }
    },
    [payload, updateSort, findSortByDamage, showError],
  );

  const handleShiner = useCallback(() => {
    const item = {
      id: uuid(),
      upc: null,
      sort: 'SHINER',
      quality: 'SHINER',
      quantity: formData.quantity,
    };

    updateSort('SHINER');
    setPayload((payload) => [item, ...payload]);
    voiceShiner.play();
  }, [formData.quantity, updateSort]);

  const handleKeyboardShortcuts = useCallback(
    (event) => {
      if (
        event.ctrlKey &&
        event.altKey &&
        [69, 72, 83, 84].includes(event.keyCode)
      ) {
        switch (event.keyCode) {
          case 69: //e
            return handleDamageChange('EMPTY')();
          case 72: //h
            return handleDamageChange('HAZMAT')();
          case 83: //'s'
            return handleShiner();
          case 84: //t
            return handleDamageChange('TRASH')();
          default:
            break;
        }
      }
    },
    [handleShiner, handleDamageChange],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyboardShortcuts);
    return () => window.removeEventListener('keyup', handleKeyboardShortcuts);
  });

  const handlePayloadChange = (event) => {
    const input = event.target.value;
    setFormData({ ...formData, [event.target.name]: input });
  };

  const handlePayloadChangeSoft = useCallback((input, target) => {
    setFormData((formData) => ({ ...formData, [target.name]: input }));
  }, []);

  // temporary function to transform upc based on customer
  const transformUPC = (upc) => {
    // disabling === warning since id is string
    // eslint-disable-next-line
    if (customer.id == 8000 && upc.length > 11) {
      return [...upc].splice(0, 11).join('');
    }
    return upc;
  };

  const processItem = async () => {
    beep.play();
    // TODO: Check if already scanned.
    const entry = { ...formData };

    // FIXME: Remove this hardcoded transform.
    // This should happen on server based on a configuration.
    entry.upc = transformUPC(entry.upc);

    const damageId = damageCodes.indexOf(entry.quality) + 1;

    const sortCodes = await api.customers.sortcodes(customer.id, {
      upc: entry.upc,
      damageId,
    });

    let item;
    if (sortCodes && sortCodes.length > 0) {
      item = sortCodes.find(({ upc }) => upc === entry.upc);
      entry.sort = item.primarySort;
    } else {
      // instead of "NOF"
      entry.sort = findSortByDamage(entry.quality);
    }

    updateSort(entry.sort);

    entry.id = uuid();
    setPayload([entry, ...payload]);

    const resetValues = Object.fromEntries(
      Object.entries(defaultValues).filter(([key]) => !locks[key]),
    );

    setFormData({ ...formData, ...resetValues });

    barcodeInputRef.current.focus();
  };

  const handleEnterKey = (event) => {
    if (formData.upc) {
      return processItem();
    }
  };

  const handleFormFieldEnter = () => {
    barcodeInputRef.current.focus();
  };

  const handleHazmat = (reason) => () => {
    handleDamageChange('HAZMAT')({ info: reason });
    setHazmatDialogOpen(false);
  };

  const handleStampChange = (selectedStamp) => () => {
    const copyOfPayload = [...payload];
    const item = copyOfPayload.shift();
    item.stamp = selectedStamp;
    setPayload([item, ...copyOfPayload]);
  };

  const handleDelete = (item) => {
    const without = payload.filter(({ id }) => id !== item.id);
    setPayload([...without]);
    barcodeInputRef.current.focus();
  };

  const handleLock = (field) => () => {
    const newValue = !locks[field];
    if (newValue === true && !formData[field]) {
      return showError('Please select an option before locking.');
    }
    setLocks({ ...locks, [field]: newValue });
    if (newValue === false) {
      setFormData({ ...formData, [field]: defaultValues[field] });
    }
  };

  const sortColor = () => {
    switch (sort) {
      case 'HAZMAT-H1':
      case 'HAZMAT-H2':
        return 'danger';
      case 'SHINER':
        return 'warning';
      default:
        return 'info';
    }
  };

  const currentStamp = useMemo(
    () =>
      payload.length > 0
        ? stamps.find(({ id }) => id === payload[0].stamp)
        : null,
    [payload],
  );

  const currentQuality = useMemo(
    () => (payload.length > 0 ? payload[0].quality : null),
    [payload],
  );

  return (
    <>
      <Dialog disableEnforceFocus={true} open={isHazmatDialogOpen}>
        <DialogTitle>Please select an option.</DialogTitle>
        <Divider />
        <DialogContent className="my-3 d-flex flex-column">
          <Button
            className="mb-2"
            size="large"
            variant="contained"
            color="primary"
            onClick={handleHazmat('Leaking')}
          >
            leaking
          </Button>
          <Button
            onClick={handleHazmat('Actuator Missing')}
            size="large"
            variant="contained"
            color="primary"
          >
            Actuator Missing
          </Button>
        </DialogContent>
        <Divider />
        <DialogActions className="d-flex justify-content-center mt-1">
          <Button
            onClick={() => setHazmatDialogOpen(false)}
            size="large"
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Paper variant="outlined" className={classes.paper}>
        {currentQuality && (
          <span className="ribbon-angle ribbon-angle--top-left ribbon-second ">
            <small style={{ fontSize: '1rem' }}>{currentQuality}</small>
          </span>
        )}
        <Typography
          className={`bg-${sortColor()} ${classes.dispositionDisplay} p-0`}
          variant="h1"
          align="center"
        >
          {sort?.trim('') || '--'}
        </Typography>
        {currentStamp && (
          <Button
            size="small"
            variant="outlined"
            className={classes.stampDisplay}
          >
            TAX: {currentStamp.description}
          </Button>
        )}
      </Paper>

      <div className="flex-grow-1 mt-2">
        <ScannedItems stamps={stamps} items={payload} onDelete={handleDelete} />
      </div>

      <Paper
        variant="outlined"
        className={`${classes.paper} border-2 border-first d-flex align-box-row justify-content-between`}
      >
        <Button
          variant="contained"
          size="large"
          className="bg-first text-white"
          onClick={onReceivePallet}
        >
          Receive <br /> Pallet
        </Button>
        <div className={classes.scanWrap}>
          <FormControl className={classes.formControl}>
            <SoftTextField
              label="UPC / Barcode"
              variant="filled"
              inputProps={{
                autoComplete: 'off',
                autoCorrect: 'off',
              }}
              name="upc"
              value={formData.upc}
              onChange={handlePayloadChangeSoft}
              inputRef={barcodeInputRef}
              onEnter={handleEnterKey}
              autoFocus
            />
          </FormControl>
          <FormControl className={classes.formControlSm}>
            <SoftTextField
              label="Quantity"
              type="number"
              variant="filled"
              name="quantity"
              inputProps={{ min: 1, autoComplete: 'off', autoCorrect: 'off' }}
              value={formData.quantity}
              selectOnFocus
              onChange={handlePayloadChangeSoft}
              onEnter={handleFormFieldEnter}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label="Damage"
              select
              variant="filled"
              name="quality"
              value={formData.quality}
              onChange={handlePayloadChange}
            >
              {damageCodes.map((code) => (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <Button
            onClick={handleLock('quality')}
            className="align-bottom mr-3"
            variant="outlined"
          >
            <span className="line-height-sm d-inline-flex flex-column align-box-row">
              {locks.quality ? <LockedIcon /> : <UnlockedIcon />}
              <span>{locks.quality ? 'LOCKED' : 'UNLOCKED'}</span>
            </span>
          </Button>
          <FormControl className={classes.formControl}>
            <TextField
              label="Tax Stamp"
              select
              variant="filled"
              name="stamp"
              value={formData.stamp}
              onChange={handlePayloadChange}
            >
              <MenuItem value="">No Stamp</MenuItem>

              {stamps.map((code, index) => (
                <MenuItem key={index} value={code.id}>
                  {code.description}
                </MenuItem>
              ))}
            </TextField>
            {/* <Button>Lock</Button> */}
          </FormControl>
          <Button
            onClick={handleLock('stamp')}
            className="align-bottom mr-3"
            variant="outlined"
          >
            <span className="line-height-sm d-inline-flex flex-column align-box-row">
              {locks.stamp ? <LockedIcon /> : <UnlockedIcon />}
              <span>{locks.stamp ? 'LOCKED' : 'UNLOCKED'}</span>
            </span>
          </Button>
        </div>

        <Button
          className="bg-first text-white"
          variant="contained"
          size="large"
        >
          Close <br /> Box
        </Button>
      </Paper>

      <Paper variant="outlined" className={classes.paper}>
        <div className="d-flex justify-content-around p-1">
          <Button
            variant="outlined"
            color="primary"
            onClick={onCustomerChange}
            size="large"
            className="mr-2"
          >
            <span className="line-height-sm">
              CHANGE
              <br /> CUSTOMER/STORE/AUTH NO
            </span>
          </Button>
          <div>
            <Button
              onClick={handleStampChange('')}
              variant="contained"
              color="primary"
              size="large"
            >
              <span className="line-height-sm">
                NO <br /> TAX STAMP
              </span>
            </Button>
          </div>
          <div>
            <Grid container spacing={4}>
              <Grid item>
                <Button
                  onClick={handleDamageChange('EMPTY')}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  <span className="line-height-sm">
                    EMPTY <br /> Package
                  </span>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleShiner}
                  className="bg-warning"
                  variant="contained"
                  size="large"
                >
                  SHINER
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleDamageChange('TRASH')}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  TRASH
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <Button
              onClick={handleDamageChange('HAZMAT-REQ')}
              variant="contained"
              color="secondary"
              size="large"
            >
              HAZMAT
            </Button>
          </div>
        </div>
      </Paper>
      <Paper className={classes.paper} variant="outlined">
        <Grid container className="d-flex justify-content-around">
          <Alert style={{ width: '33%' }} severity="info" className="">
            64 Items scanned from the current box.
          </Alert>
          <Alert style={{ width: '33%' }} severity="warning" className="">
            SHINER usage is more than average.
          </Alert>
          <Alert style={{ width: '33%' }}>
            Current Scan Rate is 4.75 Items/Hour.
          </Alert>
        </Grid>
      </Paper>
    </>
  );
};

export default ScanViewForm;
