import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';

import RackListItem from './RackListItem';
import AddRackButton from './AddRackButton';

import LightSettingsContext from './context';

const RackList = () => {
  const { racks } = useContext(LightSettingsContext);

  return (
    <Grid container spacing={3}>
      {racks.length > 0 &&
        racks.map((rack, i) => (
          <RackListItem key={i} rack={rack}>
            {rack.name}
          </RackListItem>
        ))}
      <AddRackButton />
    </Grid>
  );
};

export default RackList;
