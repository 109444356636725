import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 540px;
  margin: 0 auto;
`;

const Title = styled.h4`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`;

const Subtitle = styled.p`
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.palette.error.secondary};
  line-height: 26px;
`;

const Actions = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  > * {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const getButtonProps = (action) => {
  const buttonProps = {
    color: 'primary',
    variant: 'contained',
    onClick: action.onAction,
  };

  if (action.url) {
    buttonProps.component = Link;
    buttonProps.to = action.url;
  }

  return buttonProps;
};

const ErrorState = ({ title, subtitle, action }) => {
  const theme = useTheme();
  return (
    <Root>
      <Title theme={theme}>{title}</Title>
      {subtitle && <Subtitle theme={theme}>{subtitle}</Subtitle>}
      {action && (
        <Actions>
          <Button {...getButtonProps(action)}>{action.content}</Button>
        </Actions>
      )}
    </Root>
  );
};

const ActionType = PropTypes.shape({
  content: PropTypes.string,
  url: PropTypes.string,
  onAction: PropTypes.func,
});

ErrorState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: ActionType,
};

ErrorState.defaultProps = {
  title: 'Oops!',
  subtitle: 'Something went wrong!',
};

export default ErrorState;
