import * as React from 'react';
import { MenuItem, InputAdornment, TextField } from '@material-ui/core';
import { Field } from 'formik';

const FormField = (props) => {
  const { options, inputProps, startAdornment, endAdornment, ...field } = props;

  return (
    <Field {...field}>
      {({ field: formikField, form }) => (
        <TextField
          variant="filled"
          {...field}
          {...formikField}
          inputProps={inputProps}
          /* eslint-disable-next-line react/jsx-no-duplicate-props */
          InputProps={
            startAdornment || endAdornment
              ? {
                  startAdornment: startAdornment ? (
                    <InputAdornment position="start">
                      {startAdornment}
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                  endAdornment: endAdornment ? (
                    <InputAdornment position="end">
                      {endAdornment}
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                }
              : undefined
          }
          label={field.label}
          select={!!options}
          error={Boolean(form.touched[field.name] && form.errors[field.name])}
          fullWidth
          helperText={
            (form.touched[field.name] && form.errors[field.name]) ||
            field.helperText ||
            field.hint
          }
        >
          {options
            ? options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
            : null}
        </TextField>
      )}
    </Field>
  );
};

export default FormField;
