import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

function useDocumentTitle(title) {
  useEffect(() => {
    document.title = title;
  });
}

function DocumentTitle({ title, children }) {
  useDocumentTitle(title);

  return <Fragment>{children}</Fragment>;
}

DocumentTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DocumentTitle;
