import React, { useState } from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ShipmentList from './ShipmentList';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
  },
}));

const ShipmentListButton = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button variant="outlined" color="secondary" size="large" onClick={open}>
        LOOKUP
      </Button>
      <Dialog
        maxWidth={false}
        open={isOpen}
        onEscapeKeyDown={close}
        onBackdropClick={close}
      >
        <div className={classes.wrapper}>
          <ShipmentList {...props} />
        </div>
        <DialogActions>
          <Button onClick={close} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShipmentListButton;
