import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import api from 'src/services/api';

import LoadingState from '../LoadingState';
import ErrorState from '../ErrorState';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
}));

const CustomerPicker = ({ onSelect, selected, ...props }) => {
  const [customers, setCustomers] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    api.customers
      .list()
      .then((list) => {
        setCustomers(
          list.sort((a, b) => (a.description > b.description ? 1 : -1)),
        );
      })
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  }, []);

  const handleChange = (event, { props: { value } }) => {
    onSelect(value);
  };

  if (loading) return <LoadingState />;

  if (error) return <ErrorState subtitle={error} />;

  if (!customers) return null;

  // to manage default selection
  const filteredCustomers = () => {
    const copy = [...customers];
    if (selected) {
      const idx = copy.findIndex(({ id }) => id === selected.id);
      copy[idx] = selected;
    }
    return copy;
  };

  return (
    <FormControl className={`${classes.formControl} w-100`}>
      <TextField
        select
        label="Customer"
        id="customer-picker"
        value={selected}
        onChange={handleChange}
        variant="outlined"
        {...props}
      >
        {customers &&
          filteredCustomers().map((customer) => (
            <MenuItem key={customer.id} value={customer}>
              {customer.description}
            </MenuItem>
          ))}
      </TextField>
    </FormControl>
  );
};

export default CustomerPicker;
