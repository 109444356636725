import React, { useState, useEffect } from 'react';
import api from 'src/services/api';
import AuthContext from './context';

const AuthProvider = ({ children }) => {
  const previousAuthState = JSON.parse(window.localStorage.getItem('auth'));
  const [state, setState] = useState({ ...previousAuthState });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.on('unauthorized', handleUnauthorized);
    return () => {
      api.off('unauthorized', handleUnauthorized);
    };
  });

  const handleUnauthorized = () => {
    if (state && state.isAuthenticated) logout();
  };

  const login = async (payload) => {
    setLoading(true);
    setError(null);

    try {
      const { user, token } = await api.auth.token(payload);

      if (!user || !token)
        throw new Error('Sorry, something went wrong. Please try again');

      const currentState = {
        token,
        user,
        isAuthenticated: true,
      };
      window.localStorage.setItem('auth', JSON.stringify(currentState));
      api.setToken(token);
      setState({ ...currentState });
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const logout = () => {
    window.localStorage.removeItem('auth');
    api.setToken(null);
    setState(null);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loading,
        error,
        logout,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
