import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Chip from '@material-ui/core/Chip';
import Settings from '../Settings';
import useAuth from 'src/hooks/useAuth';
import Logo from './Sidebar/Logo';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import WbSunnyIcon from '@material-ui/icons/WbSunny';

import useSnackbar from 'src/hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    boxShadow: 'none',
    zIndex: 1100,
    backgroundColor: 'inherit',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  avatar: {
    cursor: 'pointer',
    width: 32,
    height: 32,
    margin: -4,
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
  scanToolbar: {
    height: 65,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  wFull: {
    width: '100%',
  },
  rightWrap: {
    marginRight: 12,
  },
  divider: {
    border: `1px solid ${theme.palette.divider}`,
    height: 65,
    position: 'absolute',
    left: 64,
    top: 0,
    transform: 'scaleX(0.5)',
  },
  homeBtn: { marginLeft: 12, '&:hover': { color: 'inherit !important' } },
  darkTheme: {
    ...(theme.palette.type === 'dark'
      ? {
          display: 'none',
        }
      : null),
  },
  lightTheme: {
    ...(theme.palette.type === 'light'
      ? {
          display: 'none',
        }
      : null),
  },
}));

function Header(props) {
  const { user } = useAuth();
  const classes = useStyles();

  const { showSnackbar } = useSnackbar();

  const handleClick = () => {
    window.dispatchEvent(new CustomEvent('onThemeToggle'));
  };

  const handleHelp = () => {
    showSnackbar({
      message: 'Hold on, Help is on the way!',
      variant: 'success',
    });
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="inherit"
        classes={{ root: classes.root }}
      >
        <div className={`${classes.section} ${classes.wFull}`}>
          {props.isScanPage && (
            <Toolbar disableGutters className={classes.wFull}>
              <div className={classes.scanToolbar}>
                <div style={{ display: 'flex' }}>
                  <Link to="/" style={{ color: 'inherit' }}>
                    <Logo
                      style={{
                        filter: 'saturate(0)',
                        padding: '0 5px',
                        marginTop: 3,
                      }}
                      width="64"
                    />
                  </Link>
                  <div className={classes.divider}></div>
                  <Button
                    className={classes.homeBtn}
                    component={Link}
                    to="/"
                    variant="outlined"
                  >
                    HOME
                  </Button>
                </div>
                <div className={classes.rightWrap}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-3"
                    onClick={handleHelp}
                  >
                    HELP?
                  </Button>
                  {user && (
                    <>
                      <Settings
                        activator={
                          <Chip
                            avatar={<Avatar src={user.avatarUrl}></Avatar>}
                            label={`${user.firstname} ${user.lastname}`}
                          />
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </Toolbar>
          )}
        </div>
        <div className={classes.section}>
          {!props.isScanPage && (
            <Toolbar>
              <IconButton>
                <SearchIcon />
              </IconButton>
              <IconButton onClick={handleClick}>
                <NightsStayIcon className={classes.darkTheme} />
                <WbSunnyIcon className={classes.lightTheme} />
              </IconButton>
              &nbsp;
              {user && (
                <>
                  <Settings
                    activator={
                      <Chip
                        avatar={<Avatar src={user.avatarUrl}></Avatar>}
                        label={`${user.firstname} ${user.lastname}`}
                      />
                    }
                  />
                </>
              )}
            </Toolbar>
          )}
        </div>
      </AppBar>
    </div>
  );
}

export default Header;
