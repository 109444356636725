import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';

import CatchAllListItem from './CatchAllListItem';
import AddCatchAllButton from './AddCatchAllButton';

import LightSettingsContext from './context';

const CatchAllList = () => {
  const { catchAlls } = useContext(LightSettingsContext);

  return (
    <Grid container spacing={3}>
      {catchAlls.length > 0 &&
        catchAlls.map((entry, i) => (
          <CatchAllListItem key={i} item={entry}>
            {entry.sort}
          </CatchAllListItem>
        ))}
      <AddCatchAllButton />
    </Grid>
  );
};

export default CatchAllList;
