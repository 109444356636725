import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ButtonGroup,
  Button,
} from '@material-ui/core';
import { HuePicker } from 'react-color';
import BackIcon from '@material-ui/icons/ArrowBack';

import { generateCommand, dispatchScanToLight } from 'src/utils/scan';

import NumberInput from 'src/components/NumberInput';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    '& > div': {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    display: 'block',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  back: {
    display: 'inherit',
    width: 'fit-content',
  },
  colorFieldWrap: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
  },
  numberInput: {
    width: `calc((100% - ${theme.spacing(1)}px) / 2)`,
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  buttonGroup: {
    justifyContent: 'center',
    '& button': {
      width: 150,
    },
  },
  statusButton: {
    '& span': {
      color: theme.palette.secondary.light,
    },
  },
}));

const RackLayoutEdit = ({
  layout,
  row,
  bin,
  bins,
  setSelectedBin,
  onChange,
  onClose,
}) => {
  const classes = useStyles();
  const [autoCompute, setAutoCompute] = useState(true);
  const currentBin = layout[row][bin];

  const handleChange = (event) => {
    const newLayout = [...layout];
    const current = newLayout[row][bin];
    current[event.target.name] =
      event.target.type === 'number'
        ? parseInt(event.target.value)
        : event.target.value;

    if (autoCompute && current.start && current.size) {
      console.log('AUTO COMPUTE LED POSITION FOR NEXT');
      let previous = { ...current };
      for (let i = row; i < newLayout.length; i++) {
        const rowEntry = newLayout[i];
        const step = i % 2 === 0 ? 1 : -1; // negative if odd for zig-zag pattern.
        const isSelectedRow = i === row;
        const lastBinIndex = rowEntry.length - 1;
        const isRTL = step < 0; //RightToLeft

        for (
          let j = isSelectedRow ? bin + step : isRTL ? lastBinIndex : 0;
          isRTL ? j >= 0 : j <= lastBinIndex;
          j += step
        ) {
          const binEntry = rowEntry[j];
          binEntry.start = previous.start + previous.size;
          if (!binEntry.size) binEntry.size = previous.size;
          previous = { ...binEntry };
        }
      }
    }
    simulateScan(current);
    onChange(newLayout);
  };

  const handleColorChange = (color) => {
    const newLayout = [...layout];
    newLayout[row][bin]['color'] = color.rgb;
    simulateScan(newLayout[row][bin]);
    onChange(newLayout);
  };

  const simulateScan = (bin) => {
    if (!bin.color || !bin.size || !bin.start) return;
    const command = generateCommand(bin);
    dispatchScanToLight(command);
  };

  const handleNext = () => {
    let i = row;
    let j;
    if (bin < bins - 1) {
      j = bin + 1;
    } else {
      j = 0;
      i++;
    }
    if (!layout[i] || !layout[i][j]) return onClose();
    setSelectedBin({ i, j });
  };

  const handlePrevious = () => {
    let i = row;
    let j;
    if (bin > 0) {
      j = bin - 1;
    } else {
      j = bins - 1;
      i--;
    }
    if (!layout[i] || !layout[i][j]) return onClose();
    setSelectedBin({ i, j });
  };

  const toggleAutoComputeChange = () => {
    setAutoCompute(!autoCompute);
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={onClose} className={classes.back}>
        <BackIcon />
      </IconButton>
      <FormControl className={classes.formControl}>
        <TextField
          label="Sort"
          type="text"
          name="sort"
          margin="none"
          fullWidth
          autoComplete="off"
          autoCorrect="off"
          placeholder="Use * for wildcard. Example: SAL* to match any sort starting with SAL"
          value={currentBin.sort}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange}
          variant="outlined"
          size="small"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <NumberInput
          label="Start"
          type="number"
          margin="none"
          name="start"
          autoComplete="off"
          autoCorrect="off"
          value={currentBin.start}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 1,
          }}
          onChange={handleChange}
          variant="outlined"
          size="small"
          className={classes.numberInput}
        />
        {/* TODO: Carve out this as a sepaerate component */}
        <NumberInput
          label="Length"
          type="number"
          margin="none"
          name="size"
          autoComplete="off"
          autoCorrect="off"
          value={currentBin.size}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 1,
          }}
          onChange={handleChange}
          variant="outlined"
          size="small"
          className={classes.numberInput}
        />
      </FormControl>
      <FormControl
        align="center"
        className={classes.formControl}
        variant="outlined"
      >
        <InputLabel htmlFor="component-outlined">LED Color</InputLabel>
        <div className={classes.colorFieldWrap} id="component-outlined">
          <HuePicker
            color={currentBin.color}
            onChangeComplete={handleColorChange}
          />
        </div>
      </FormControl>

      <ButtonGroup
        variant="outlined"
        size="small"
        className={classes.buttonGroup}
      >
        <Button onClick={handlePrevious}>Previous</Button>
        <Button className={classes.statusButton} disabled>
          Shelf {row + 1}, Bin {bin + 1}
        </Button>
        <Button width="50" onClick={handleNext}>
          Next
        </Button>
      </ButtonGroup>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={autoCompute}
              onChange={toggleAutoComputeChange}
            />
          }
          label="Auto compute the positions for succeeding bins."
        />
      </FormControl>
    </div>
  );
};

export default RackLayoutEdit;
