import { createMuiTheme } from '@material-ui/core/styles';
import { blue, pink } from '@material-ui/core/colors/';

const makeTheme = (type = 'light') =>
  createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      type,
      primary: blue,
      secondary: pink,
    },
    overrides: {
      MuiButton: {
        label: {
          whiteSpace: 'nowrap',
        },
        sizeLarge: {
          padding: '10px 38px',
          fontSize: '0.875rem',
          minHeight: 64,
        },
      },
      MuiFilledInput: {
        root: {
          borderRadius: 4,
          overflow: 'hidden',
        },
        underline: {
          '&:before': {
            content: 'none',
          },
        },
      },
    },
  });

export default makeTheme;
