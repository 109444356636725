import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    height: 50,
    padding: theme.spacing(1),
    textAlign: 'center',
    background: theme.palette.action.disabledBackground,
    whiteSpace: 'nowrap',
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  text: {
    alignSelf: 'center',
  },
}));

const RackLayout = ({ layout, onBinClick }) => {
  const classes = useStyles();
  return (
    <>
      {layout.map((row, i) => (
        <Grid container key={i}>
          {row.map((bin, j) => (
            <Grid key={j} item xs>
              <Box
                onClick={() => onBinClick(bin, i, j)}
                align="right"
                className={classes.root}
                borderBottom={1}
                borderRadius={3}
                boxShadow={1}
                style={{
                  borderColor: bin.color
                    ? `rgb(${bin.color.r},${bin.color.g},${bin.color.b})`
                    : '',
                }}
              >
                <Typography className={classes.text}>{bin.sort}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      ))}
    </>
  );
};

export default RackLayout;
