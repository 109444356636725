import Resource from '../Resource';

class Customers extends Resource {
  static path = '/customers';

  static include = [Resource.LIST];

  shipment = Resource.method({
    method: 'GET',
    path: '/:id/shipment',
  });

  items = Resource.method({
    method: 'GET',
    path: '/:id/items',
  });

  sortcodes = Resource.method({
    method: 'GET',
    path: '/:id/sortcodes',
  });

  stores = Resource.method({
    method: 'GET',
    path: '/:id/stores',
  });

  header = Resource.method({
    method: 'GET',
    path: '/:id/header',
  });
}

export default Customers;
