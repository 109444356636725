import Resource from '../Resource';

class Customers extends Resource {
  static path = '/config';

  static include = [Resource.LIST, Resource.GET];

  createOrUpdate = Resource.method({
    method: 'POST',
    path: '/',
  });
}

export default Customers;
