import React from 'react';
import { Button } from '@material-ui/core/';
import useAuth from 'src/hooks/useAuth';

const LogoutButton = () => {
  const { logout } = useAuth();
  return <Button onClick={logout}>Logout</Button>;
};

export default LogoutButton;
