import React, { useState, useContext } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  FormControl,
  TextField,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import LightSettingsContext from './context';
import useSnackbar from 'src/hooks/useSnackbar';

import NumberInput from 'src/components/NumberInput';
import RackLayout from './RackLayout';
import RackLayoutEdit from './RackLayoutEdit';

import { defaultColor } from 'src/utils/scan';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 120,
  },
  block: {
    padding: theme.spacing(1),
    textAlign: 'center',
    background: theme.palette.action.disabledBackground,
    whiteSpace: 'nowrap',
    margin: theme.spacing(1),
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  buttonRow: {
    textAlign: 'center',
    '&> button': {
      margin: theme.spacing(1),
    },
  },
}));

// TODO: Organize this component better;
// show suggestions for sort input

const AddEditRack = ({ rack, onClose }) => {
  const classes = useStyles();
  const [state, setState] = useState(rack || { rows: '', bins: '', name: '' });
  const [selectedBin, setSelectedBin] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { racks, updateRacks } = useContext(LightSettingsContext);
  const { showSnackbar } = useSnackbar();

  const makeLayout = (newState) => {
    const getRows = () =>
      Array.from(Array(parseInt(newState.bins)).keys()).map(() => ({
        color: defaultColor,
        sort: '',
        size: '',
        start: '',
      }));
    const layout = Array.from(Array(parseInt(newState.rows)).keys()).map(() => [
      ...getRows(),
    ]);

    if (state.layout) {
      // close the edit form, because it throws error if the open bin is deleted.
      handleEditClose();
      // merge previous & current versions.
      layout.forEach((row, i) => {
        row.forEach((bin, j) => {
          if (state.layout[i] && state.layout[i][j])
            layout[i][j] = { ...state.layout[i][j] };
        });
      });
    } else {
      layout[0][0].start = getNextPosition();
    }
    return layout;
  };

  const getNextPosition = () => {
    if (!racks || !racks[racks.length - 1]) return 1;
    const lastRack = racks[racks.length - 1].layout;
    return lastRack.reduce((position, row) => {
      row.forEach((bin) => {
        if (position < bin.start + bin.size) position = bin.start + bin.size;
      });
      return position;
    }, 1);
  };

  const handleChange = (event) => {
    const newState = {
      ...state,
      [event.target.name]: event.target.value,
    };

    if (
      (event.target.name === 'bins' || event.target.name === 'rows') &&
      newState.rows &&
      newState.bins
    ) {
      newState.layout = makeLayout(newState);
    }
    setState(newState);
  };

  // FIXME: This shouldn't be just time.
  const generateRackId = () => {
    return Math.floor(+new Date() * Math.random());
  };

  const handleEditClick = (bin, i, j) => {
    setSelectedBin({ i, j });
  };

  const handleLayoutChange = (layout) => {
    setState({ ...state, layout });
  };

  const handleEditClose = () => {
    setSelectedBin(null);
  };

  const hanldeSubmit = async () => {
    if (!state.layout) {
      return showSnackbar({
        message:
          'Please enter the number of shelves and bins to configure the rack.',
        variant: 'error',
      });
    }
    // this component doesn't do individual rack save as the
    // api is not supporting rack update. API expects all the racks
    const id = rack ? rack.id : generateRackId();
    const payload = { id, ...state };
    setSubmitting(true);
    await updateRacks(payload);
    setSubmitting(false);
  };

  return (
    <Box p={3} className={classes.root}>
      <Typography className={classes.title} align="center" variant="subtitle1">
        Rack configuration
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs>
          <Paper variant="outlined" className={classes.paper}>
            <FormControl className={classes.formControl}>
              <TextField
                label="Name"
                type="text"
                margin="normal"
                name="name"
                autoComplete="false"
                autoCorrect="false"
                value={state.name}
                required
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <NumberInput
                label="Shelves"
                type="number"
                margin="normal"
                name="rows"
                autoComplete="false"
                autoCorrect="false"
                value={state.rows}
                required
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <NumberInput
                label="Bins"
                type="number"
                margin="normal"
                name="bins"
                autoComplete="false"
                autoCorrect="false"
                value={state.bins}
                required
                onChange={handleChange}
              />
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container direction="column">
              {state.layout && (
                <>
                  {selectedBin ? (
                    <RackLayoutEdit
                      layout={state.layout}
                      row={selectedBin.i}
                      bin={selectedBin.j}
                      bins={state.bins}
                      rows={state.rows}
                      setSelectedBin={setSelectedBin}
                      onClose={handleEditClose}
                      onChange={handleLayoutChange}
                    />
                  ) : (
                    <RackLayout
                      onBinClick={handleEditClick}
                      layout={state.layout}
                    />
                  )}
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs className={classes.buttonRow}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={hanldeSubmit}
            disabled={submitting}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditRack;
