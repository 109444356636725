import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { CancelOutlined as DeleteIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '40vh',
  },
  coloredRow: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ScannedItems = ({ items, stamps, onDelete }) => {
  const classes = useStyles();
  const [height, setHeight] = useState('100%');
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      setHeight(`${ref.current.offsetHeight}px`);
    }
  }, []);

  const handleDelete = (item) => {
    onDelete(item);
  };

  return (
    <TableContainer
      className={classes.container}
      component={Paper}
      variant="outlined"
      ref={ref}
      style={{ height }}
    >
      <Table
        stickyHeader
        className={classes.table}
        size="small"
        aria-label="Scanned items"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Item UPC / Barcode</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Vendor</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center">Damage</TableCell>
            <TableCell align="center">Sort</TableCell>
            <TableCell align="center">
              Hazmat <br />
              Class
            </TableCell>
            <TableCell align="center">Tax Stamp</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length === 0 && (
            <TableRow className="p-1">
              <TableCell colSpan={9} className="p-4" align="center">
                No items scanned yet!
              </TableCell>
            </TableRow>
          )}
          {items.map((item, index) => (
            <TableRow className="p-1" key={index}>
              <TableCell className="p-2" align="center">
                {index === 0 && (
                  <span
                    className="position-absolute m-1 badge badge-primary"
                    style={{ left: -8, top: 84 }}
                  >
                    LAST SCANNED
                  </span>
                )}
                {item.upc}
              </TableCell>
              <TableCell className="p-2" align="center">
                Item description goes here
              </TableCell>
              <TableCell className="p-2" align="center">
                Unknown
              </TableCell>
              <TableCell className="p-2" align="center">
                {item.quantity}
              </TableCell>
              <TableCell className="p-2" align="center">
                {item.quality}
              </TableCell>
              <TableCell className="p-2" align="center">
                {item.sort}
              </TableCell>
              <TableCell className="p-2" align="center">
                {item.info}
              </TableCell>
              <TableCell className="p-2" align="center">
                {stamps.find(({ id }) => id === item.stamp)?.description}
              </TableCell>

              <TableCell className="p-2" align="center">
                <IconButton onClick={() => handleDelete(item)}>
                  <DeleteIcon fontSize="large" color="secondary" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ScannedItems;
