import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute';
import LoginView from 'src/views/LoginView';
import DashboardView from 'src/views/DashboardView';
import ScanView from 'src/views/ScanView';
import SettingsView from 'src/views/SettingsView';

const Navigator = () => (
  <Switch>
    <Route exact path="/login" component={LoginView} />
    <ProtectedRoute exact path="/dashboard" component={DashboardView} />
    <ProtectedRoute path="/scan" component={ScanView} />
    <ProtectedRoute path="/settings" component={SettingsView} />

    <Redirect to="/dashboard" />
  </Switch>
);

export default Navigator;
