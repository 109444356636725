import React from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import Page from 'src/components/Page';
import LightSettings from 'src/components/LightSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  paper: {
    height: 'auto',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  tabRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const SettingsView = ({ location, match, props }) => {
  const classes = useStyles();

  return (
    <Page title="Scan">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.tabRoot}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={location.pathname}
              aria-label="Settings menu"
              className={classes.tabs}
            >
              <Tab
                label="Light"
                component={Link}
                to={`${match.url}/light`}
                value={`${match.url}/light`}
              />
            </Tabs>
            <Switch>
              <Route exact path={`${match.url}/light`}>
                <LightSettings />
              </Route>
              <Redirect to={`${match.url}/light`} />
            </Switch>
          </div>
        </Paper>
      </div>
    </Page>
  );
};

export default SettingsView;
