import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import ActivityIndicator from '../ActivityIndicator';

const useStyles = makeStyles((theme) => ({
  loader: {
    position: 'absolute',
  },
}));

const LoadingButton = ({ children, disabled, loading, ...rest }) => {
  const classes = useStyles();
  return (
    <Button {...rest} disabled={disabled || loading}>
      {children}
      {loading && <ActivityIndicator className={classes.loader} size={30} />}
    </Button>
  );
};

export default LoadingButton;
