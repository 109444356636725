import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import Drawer from '../Drawer';
import User from './User';

import useAuth from 'src/hooks/useAuth';

const SettingsDrawer = styled(Drawer)`
  color: ${({ theme }) => theme};
`;

const onClick = (activator, callback) => (event) => {
  if (activator.props.onClick) {
    activator.props.onClick(event);
  }

  if (event.defaultPrevented) {
    return;
  }

  callback();
};

const Settings = ({ activator, ...props }) => {
  const [isOpen, setOpen] = useState(false);
  const { user } = useAuth();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const anchor = React.cloneElement(activator, {
    onClick: onClick(activator, openDrawer),
  });

  return (
    <Fragment>
      {anchor}
      <SettingsDrawer {...props} open={isOpen} onRequestClose={closeDrawer}>
        <User user={user} />
      </SettingsDrawer>
    </Fragment>
  );
};

export default Settings;
