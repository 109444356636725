import { useCallback, useRef, useState } from 'react';

import ReactDOMServer from 'react-dom/server';
import Keyboard from 'react-simple-keyboard';

import { Popper, TextField as MuiTextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import 'react-simple-keyboard/build/css/index.css';

import {
  Backspace as BackspaceIcon,
  KeyboardReturn as KeyboardReturnIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  keyboard: {
    border: `1px solid ${theme.palette.divider} !important`,
    background: theme.palette.background.default,
    '& .hg-button': {
      height: 60,
      padding: 0,
      fontSize: '1rem',
      fontWeight: 'bold',
      background: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const TextField = ({
  inputProps,
  onChange,
  onEnter,
  selectOnFocus,
  onFocus,
  onBlur,
  onClick,
  onKeyPress,
  ...rest
}) => {
  const keyboardRef = useRef();
  const popperRef = useRef();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openKeypad = useCallback((event) => {
    setAnchorEl(event.target);
    setTimeout(() => {
      keyboardRef?.current?.setInput(event.target.value);
      popperRef?.current?.update();
    }, 100);
  }, []);

  const closeKeypad = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleEnter = useCallback(
    (button) => {
      if (button === '{enter}') {
        anchorEl.blur();
        closeKeypad();
        if (onEnter) onEnter();
      }
    },
    [anchorEl, closeKeypad, onEnter],
  );

  const handleKeypadChange = useCallback(
    (value) => {
      if (selectOnFocus) {
        value = value.replace(document.getSelection().toString(), '');
        keyboardRef?.current?.setInput(value);
      }
      onChange(value, anchorEl);
    },
    [onChange, anchorEl, selectOnFocus],
  );

  const handleChange = useCallback(
    (event) => {
      const input = event.target.value;
      if (Boolean(anchorEl)) {
        const currentVal = keyboardRef?.current?.getInput();
        if (input !== currentVal) {
          closeKeypad();
          keyboardRef?.current?.setInput(input);
        }
      }
      onChange(input, event.target);
    },
    [onChange, anchorEl, closeKeypad],
  );

  const handleFocus = useCallback(
    (event) => {
      if (selectOnFocus) {
        event.target.select();
      }
      if (onFocus) onFocus(event);
    },
    [onFocus, selectOnFocus],
  );

  const handleBlur = useCallback(
    (event) => {
      closeKeypad(event);
      if (onBlur) onBlur(event);
    },
    [closeKeypad, onBlur],
  );

  const handleClick = useCallback(
    (event) => {
      openKeypad(event);
      if (onClick) onClick(event);
    },
    [openKeypad, onClick],
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        anchorEl?.blur();
        closeKeypad(event);
        if (onEnter) onEnter(event);
      }
      if (onKeyPress) onKeyPress(event);
    },
    [closeKeypad, anchorEl, onEnter, onKeyPress],
  );

  return (
    <>
      <MuiTextField
        inputProps={{
          autoComplete: 'off',
          autoCorrect: 'off',
          ...inputProps,
        }}
        onChange={handleChange}
        onClick={handleClick}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        {...rest}
      />
      <Popper
        id="keyboard-pop"
        style={{ width: 300, zIndex: 9999 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        popperRef={popperRef}
      >
        <Keyboard
          keyboardRef={(r) => (keyboardRef.current = r)}
          layoutName="default"
          theme={`${classes.keyboard} hg-theme-default`}
          layout={{
            default: ['1 2 3', '4 5 6', '7 8 9', '. 0 {bksp}', '{enter}'],
          }}
          display={{
            '{enter}': `Enter ${ReactDOMServer.renderToStaticMarkup(
              <KeyboardReturnIcon />,
            )}`,
            '{bksp}': ReactDOMServer.renderToStaticMarkup(<BackspaceIcon />),
          }}
          preventMouseDownDefault={true}
          onChange={handleKeypadChange}
          onKeyPress={handleEnter}
        />
      </Popper>
    </>
  );
};

export default TextField;
