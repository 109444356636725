import React from 'react';

const DotIcon = (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="12" cy="12" r="6" fill={props.color || 'currentColor'} />
    </svg>
  );
};

export default DotIcon;
