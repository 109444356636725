import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import Modal from '../Modal';

const Root = styled.aside`
  width: 375px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? 0 : -500)}px;
  z-index: 9999;
  background: ${({ theme }) => theme.palette.background.paper};
  box-shadow: -3px 0 55px rgba(0, 0, 0, 0.3);
  font-size: 12px;
  overflow: hidden;
  transition: right 0.2s ease-out, width 0.2s ease-out;
`;

const Drawer = ({ open, children, ...props }) => {
  const theme = useTheme();

  return (
    <Modal open={open} keepMounted transparent {...props}>
      <Root open={open} theme={theme}>
        {children}
      </Root>
    </Modal>
  );
};

Drawer.defaultProps = {
  open: false,
};

export default Drawer;
