import React, { useState, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Button,
  Grid,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import CustomerPicker from 'src/components/CustomerPicker';
import StoreSelector from 'src/components/StoreSelector';
import { default as SoftTextField } from 'src/components/TextField';

import useSnackbar from 'src/hooks/useSnackbar';
import LoadingButton from 'src/components/LoadingButton';
import api from 'src/services/api';

const useStyles = makeStyles({
  root: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    padding: 24,
  },

  divider: {
    width: 1,
    height: '80%',
    background: `linear-gradient(
      to bottom, 
      rgba(0, 0, 0, 0) 0%, 
      rgba(0, 0, 0, 0.12) 40%, 
      rgba(0, 0, 0, 0.12) 70%, 
      rgba(0, 0, 0, 0)
    )`,
  },
});

const CustomerSelectDialog = ({
  onClose,
  customer,
  store,
  label,
  authNo,
  isPalletMode,
}) => {
  const [state, setState] = useState({
    customer: customer || '',
    store: store || '',
    label: label || '',
    authNo: authNo || '',
  });
  const [mode, setMode] = useState(isPalletMode ? 'pallet' : 'label');
  const [isChecking, setChecking] = useState(false);
  const authNoRef = useRef();
  const labelRef = useRef();

  const { showSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleSelect = (key) => (value) => {
    if (key === 'customer') {
      setState({ ...state, [key]: value, store: '', authNo: '', label: '' });
      labelRef?.current?.focus();
      console.log(labelRef?.current);
    } else {
      setState({ ...state, [key]: value });
    }

    if (key === 'store' && authNoRef.current) {
      console.log(authNoRef.current);
      authNoRef.current.focus();
    }
  };

  const handleChangeSoft = (key) => (value) => {
    setState({ ...state, [key]: value });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') handleContinue();
  };

  const handleCancel = () => {
    return onClose();
  };

  const handleContinue = async () => {
    setChecking(true);
    try {
      const values = { ...state };
      if (values.store && values.authNo) {
        values.label = `91${values.store.storenum}${values.authNo}`;
      } else if (values.label) {
        const authNo = values.label.substr(7);
        const storeNo = values.label.substr(2, 5);

        const stores = await api.customers.stores(values.customer.id, {
          query: storeNo,
        });

        const store = stores[0];

        if (!store) {
          setChecking(false);
          return showSnackbar({ message: 'Invalid Label', variant: 'error' });
        }
        values.store = store;
        values.authNo = authNo;
      } else {
        setChecking(false);
        return showSnackbar({
          message: 'Please enter the scan label or select store & auth number.',
          variant: 'error',
        });
      }
      const { headers } = await api.customers.header(values.customer.id, {
        storeId: values.store.id,
        authNo: values.authNo,
      });

      if (headers === 0) {
        setChecking(false);
        return showSnackbar({ message: 'Invalid Label', variant: 'error' });
      }

      return onClose(values);
    } catch (error) {
      setChecking(false);
      return showSnackbar({ message: error.message, variant: 'error' });
    }
  };

  return (
    <Dialog disableEnforceFocus={true} open={true} fullWidth maxWidth="md">
      <DialogTitle>
        {mode === 'pallet' ? 'Receive Pallet' : 'Select Customer & Store/Label'}
      </DialogTitle>
      <Divider />
      <DialogContent className="mt-3">
        <Grid container className="d-flex justify-content-center">
          <Grid item md={4}>
            <CustomerPicker
              onSelect={handleSelect('customer')}
              selected={state.customer}
              fullWidth
            />
          </Grid>
        </Grid>
        {state.customer && (
          <>
            {mode === 'label' && (
              <Grid
                container
                className="mt-3 d-flex justify-content-center align-items-stretch"
                spacing={4}
              >
                <Grid item md={4} className="d-flex align-items-center">
                  <Grid container>
                    <Grid item md={12}>
                      <StoreSelector
                        onChange={handleSelect('store')}
                        value={state.store}
                        className="w-100"
                        customerId={state.customer?.id}
                        TextFieldProps={{
                          helperText:
                            'Please select a store to enter the auth number',
                        }}
                      />
                    </Grid>
                    {state.store && (
                      <Grid item md={12} className="mt-3">
                        <SoftTextField
                          variant="outlined"
                          className="w-100"
                          label="Auth Number"
                          inputRef={authNoRef}
                          value={state.authNo}
                          InputLabelProps={{
                            shrink: !!state.authNo,
                          }}
                          onKeyPress={handleKeyPress}
                          onChange={handleChangeSoft('authNo')}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={2}
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  <div className={classes.divider} />
                  <p className="m-0 font-weight-light font-size-lg">OR</p>
                  <div className={classes.divider} />
                </Grid>
                <Grid item md={4}>
                  <SoftTextField
                    variant="outlined"
                    className="w-100"
                    label="Scan Label"
                    inputRef={labelRef}
                    autoFocus
                    value={state.label}
                    InputLabelProps={{
                      shrink: !!state.label,
                    }}
                    onKeyPress={handleKeyPress}
                    onChange={handleChangeSoft('label')}
                  />
                </Grid>
              </Grid>
            )}
            {mode === 'pallet' && (
              <Grid
                container
                className="mt-3 d-flex justify-content-center align-items-stretch"
                spacing={4}
              >
                <Grid item md={4}>
                  <SoftTextField
                    variant="outlined"
                    className="w-100"
                    fullWidth
                    label="Pallet Number"
                    value={state.pallet}
                    InputLabelProps={{
                      shrink: !!state.pallet,
                    }}
                    onKeyPress={handleKeyPress}
                    onChange={handleChangeSoft('label')}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </DialogContent>

      <Divider className="mt-3" />
      <DialogActions className="d-flex justify-content-between">
        <div>
          {mode === 'label' && (
            <Button
              variant="contained"
              size="large"
              onClick={() => setMode('pallet')}
              className="bg-first text-white"
            >
              Receive <br /> Pallet
            </Button>
          )}
          {mode === 'pallet' && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setMode('label')}
              size="large"
              className="mr-2"
            >
              <span className="line-height-sm">
                ENTER
                <br /> AUTH NO/SCAN LABEL
              </span>
            </Button>
          )}
        </div>
        <div>
          <Button
            onClick={handleCancel}
            size="large"
            color="secondary"
            className="mr-3"
            variant="contained"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isChecking}
            onClick={handleContinue}
            color="primary"
            variant="contained"
            size="large"
          >
            Continue
          </LoadingButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerSelectDialog;
