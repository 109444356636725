// FIXME: WTF...Why doesn't the local take priority over the global!
import EventEmitter from 'events';
import { setupCache, serializeQuery } from 'axios-cache-adapter';
import env from 'src/env';

import Auth from './resources/Auth';
import Customers from './resources/Customers';
import Config from './resources/Config';

const resources = { Auth, Customers, Config };

const self = Symbol('self');

export class API {
  static DEFAULT_HOST = env('API_BASE_URL');
  static DEFAULT_PROTOCOL = 'http';
  static DEFAULT_BASE_PATH = '';
  static DEFAULT_VERSION = '';
  static DEFAULT_TIMEOUT = 30000;

  constructor() {
    this[self] = {
      host: env('API_BASE_URL'),
      protocol: API.DEFAULT_PROTOCOL,
      basePath: API.DEFAULT_BASE_PATH,
      version: API.DEFAULT_VERSION,
      timeout: API.DEFAULT_TIMEOUT,
      token: null,
      cache: null,
    };

    // restore token from localstorage
    const auth = window.localStorage.getItem('auth');
    if (auth) {
      const { token } = JSON.parse(auth);
      this.setToken(token);
    }

    Object.defineProperty(this, 'emitter', {
      value: new EventEmitter(),
      enumerable: false,
      configurable: false,
      writeable: false,
    });

    this.on = this.emitter.on.bind(this.emitter);
    this.off = this.emitter.off.bind(this.emitter);

    Object.entries(resources).forEach(([name, Resource]) => {
      this[name[0].toLowerCase() + name.substring(1)] = new Resource(this);
    });
  }

  const(name) {
    return this.constructor[name];
  }

  set(key, value) {
    this[self][key] = value;
  }

  get(key) {
    return this[self][key];
  }

  setToken(value) {
    this[self]['token'] = value;
    let cache = null;
    if (value) {
      const signature = value.split('.')[2];
      cache = setupCache({
        maxAge: 15 * 60 * 1000,
        key: (req) => req.url + signature + serializeQuery(req),
      });
    }
    this[self]['cache'] = cache;
  }
}

const api = new API();

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  window.api = api;
}

export default api;
