import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'src/components/Page';
import ShipmentListButton from 'src/components/ShipmentListButton';
import CustomerSelectDialog from './CustomerSelectDialog';
import ScanItemForm from './ScanItemForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  paper: {
    height: 'auto',
    padding: '10px 10px',
  },
  buttonRow: {
    display: 'inline-flex',
    position: 'absolute',
    right: '65px',
    marginTop: theme.spacing(4),
  },
  resetButton: {
    marginLeft: theme.spacing(1),
  },
}));

const ScanView = (props) => {
  const scanInputRef = useRef();
  const classes = useStyles();
  const [isCustomerSelectionOpen, setCustomerSelectionOpen] = useState(true);
  const [customer, setCustomer] = useState('');
  const [store, setStore] = useState('');
  const [authNo, setAuthNo] = useState('');
  const [scanLabel, setScanLabel] = useState('');
  const [temp, setTemp] = useState(null);
  const [isPalletMode, setPalletMode] = useState(false);

  const history = useHistory();

  const onCustomerSelect = () => {
    setTimeout(() => {
      scanInputRef.current?.focus();
    }, 0);
  };

  const handleStoreChangeTrigger = (value) => {
    setCustomerSelectionOpen(true);
    setTemp({ store, authNo, scanLabel });
    setStore('');
    setAuthNo('');
    setScanLabel('');
  };

  const handleReceivePallet = (value) => {
    setCustomerSelectionOpen(true);
    setTemp({ store, authNo, scanLabel });
    setStore('');
    setAuthNo('');
    setScanLabel('');
    setPalletMode(true);
  };

  const handleCustomerSelection = (values) => {
    setCustomerSelectionOpen(false);
    setPalletMode(false);

    if (values) {
      setCustomer(values.customer);
      setStore(values.store);
      setAuthNo(values.authNo);
      setScanLabel(values.label);
      onCustomerSelect();
      return;
    } else if (customer && temp) {
      setStore(temp.store);
      setAuthNo(temp.authNo);
      setScanLabel(temp.scanLabel);
      setTemp(null);
      return;
    }

    if (!customer) {
      history.push('/');
    }
  };

  return (
    <Page title="Scan">
      {isCustomerSelectionOpen && (
        <CustomerSelectDialog
          customer={customer}
          store={store}
          authNo={authNo}
          label={scanLabel}
          onClose={handleCustomerSelection}
          isPalletMode={isPalletMode}
        />
      )}
      {scanLabel && (
        <div className={classes.root}>
          <Paper variant="outlined" className={classes.paper}>
            <Grid container className="justify-content-around">
              <Grid item md={6}>
                <Grid container className="d-flex justify-content-around">
                  <Grid item className="d-flex flex-column align-items-center">
                    <Typography variant="body1">CUSTOMER</Typography>
                    <Typography variant="h6">{customer.description}</Typography>
                  </Grid>
                  <Grid item className="d-flex flex-column align-items-center">
                    <Typography variant="body1">STORE</Typography>
                    <Typography variant="h6">
                      {store.storeName} (#{store.storenum})
                    </Typography>
                    <Typography className="font-weight-bold" variant="body2">
                      {[store.address1, store.city, store.state].join(', ')}
                    </Typography>
                  </Grid>
                  <Grid item className="d-flex flex-column align-items-center">
                    <Typography variant="body1">AUTH NO</Typography>
                    <Typography variant="h6">{authNo}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="align-box-row">
                <ShipmentListButton customerId={customer.id} />
              </Grid>
            </Grid>
          </Paper>

          <ScanItemForm
            onCustomerChange={handleStoreChangeTrigger}
            onReceivePallet={handleReceivePallet}
            customer={customer}
          />
        </div>
      )}
    </Page>
  );
};

export default ScanView;
