import React, { useState, useContext, useMemo } from 'react';
import { Box, FormControl, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import NumberInput from 'src/components/NumberInput';
import LightSettingsContext from './context';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 'inherit',
    marginTop: theme.spacing(2),
  },
}));

const LifetimeForm = (props) => {
  const classes = useStyles();
  const [lifetime, setLifetime] = useState(props.lifetime / 1000 || '');
  const { updateLifetime } = useContext(LightSettingsContext);

  const handleLifetimeChange = (event) => setLifetime(event.target.value);

  const handleClick = () => {
    // save as microseconds
    updateLifetime(lifetime * 1000);
  };

  const hasEdited = useMemo(() => {
    return props.lifetime / 1000 !== parseInt(lifetime);
  }, [lifetime, props.lifetime]);

  return (
    <Box display="flex" flexDirection="column" width="max-content">
      <FormControl>
        <NumberInput
          placeholder="In seconds"
          value={lifetime}
          inputProps={{ min: 1 }}
          helperText="Number of seconds the LEDs should stay lit."
          onChange={handleLifetimeChange}
        ></NumberInput>
      </FormControl>

      {hasEdited && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClick}
        >
          Save
        </Button>
      )}
    </Box>
  );
};

export default LifetimeForm;
