import { useSnackbar as useNotiSnackbar } from 'notistack';

const useSnackbar = () => {
  const { enqueueSnackbar } = useNotiSnackbar();

  const showSnackbar = ({ variant, message, ...props }) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      ...props,
    });
  };

  return { showSnackbar };
};

export default useSnackbar;
