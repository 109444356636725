import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Divider } from '@material-ui/core';

import { CheckCircleOutline } from '@material-ui/icons';
import LoadingState from '../LoadingState';
import ErrorState from '../ErrorState';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  btn: {
    marginTop: theme.spacing(4),
  },
  readyIcon: {
    fontSize: theme.spacing(10),
    color: theme.palette.success.main,
  },
  divider: {
    marginTop: theme.spacing(3),
  },
}));

const AppStatus = ({ onLoginRequest }) => {
  const classes = useStyles();
  const [status, setStatus] = useState('loading');

  const handleConfigLoad = () => setStatus('ready');
  const handleConfigLoadError = () => setStatus('error');

  useEffect(() => {
    window.addEventListener('on-config-load', handleConfigLoad, false);
    window.addEventListener(
      'on-config-load-error',
      handleConfigLoadError,
      false,
    );

    return () => {
      window.removeEventListener('on-config-load', handleConfigLoad, false);
      window.removeEventListener(
        'on-config-load-error',
        handleConfigLoadError,
        false,
      );
    };
  });

  if (status === 'loading') {
    return <LoadingState />;
  }

  if (status === 'error') {
    return (
      <ErrorState
        title="Oops! Something went wrong"
        subtitle="Please check the network connection and relaunch the application."
      />
    );
  }

  return (
    <div className={classes.root}>
      <Typography>
        <CheckCircleOutline className={classes.readyIcon} />
      </Typography>
      <Typography variant="h6">Scan to light is ready.</Typography>
      <Typography variant="caption">
        Please keep this application running and continue to webscan.
      </Typography>
      <Divider className={classes.divider} />
      <Button
        className={classes.btn}
        onClick={onLoginRequest}
        variant="outlined"
        size="small"
        color="primary"
      >
        Login to configure
      </Button>
    </div>
  );
};

export default AppStatus;
