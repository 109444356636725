import React from 'react';
import { Typography } from '@material-ui/core';
import CatchAllListItem from './CatchAllListItem';

const AddRackButton = (props) => {
  return (
    <CatchAllListItem {...props}>
      <Typography>+</Typography>
    </CatchAllListItem>
  );
};

export default AddRackButton;
