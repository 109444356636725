import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import RackList from './RackList';

import LightSettingsContext from './context';
import useAPI from 'src/hooks/useAPI';
import api from 'src/services/api';

import LoadingState from 'src/components/LoadingState';
import ErrorState from 'src/components/ErrorState';
import LifetimeForm from './LifetimeForm';
import CatchAllList from './CatchAllList';

const configKey = 'SCAN_TO_LIGHT_CONFIG';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  rackRoot: {
    flexGrow: 1,
  },
  title: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
}));

const LightSettings = () => {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const { error, data, loading, refetch } = useAPI((api, params) =>
    api.config.get(configKey, params),
  );

  const racks = useMemo(() => {
    // sort by start position
    return data && data.value && data.value.racks
      ? data.value.racks.sort(
          (a, b) => a.layout[0][0].start - b.layout[0][0].start,
        )
      : [];
  }, [data]);

  const catchAlls = useMemo(() => {
    // sort by start position
    return data && data.value && data.value.catchAlls
      ? data.value.catchAlls.sort((a, b) => a.start - b.start)
      : [];
  }, [data]);

  const update = (payload) => {
    setSaving(true);
    api.config
      .createOrUpdate({
        key: configKey,
        value: { ...data.value, ...payload },
      })
      .then(refetch)
      .finally(() => setSaving(false));
  };

  const updateRacks = (rack) => {
    const others = [...racks.filter(({ id }) => id !== rack.id)];
    return update({ racks: [...others, rack] });
  };

  const updateCatchAlls = (item) => {
    const others = [...catchAlls.filter(({ sort }) => sort !== item.sort)];
    return update({ catchAlls: [...others, item] });
  };

  const updateLifetime = (lifetime) => update({ lifetime });

  if (loading || saving) return <LoadingState />;

  if (error && error.response && error.response.status !== 404)
    return <ErrorState subtitle={error.message} />;

  return (
    <LightSettingsContext.Provider
      value={{ racks, catchAlls, updateRacks, updateLifetime, updateCatchAlls }}
    >
      <Box display="flex" flexDirection="column" className={classes.root}>
        <Box p={3}>
          <Typography className={classes.title} variant="subtitle1">
            Lifetime
          </Typography>
          <LifetimeForm lifetime={data.value.lifetime}></LifetimeForm>
        </Box>

        <Box p={3}>
          <Typography className={classes.title} variant="subtitle1">
            Catch all
          </Typography>
          <CatchAllList />
        </Box>
        <Box p={3} className={classes.rackRoot}>
          <Typography className={classes.title} variant="subtitle1">
            Racks
          </Typography>
          <RackList />
        </Box>
      </Box>
    </LightSettingsContext.Provider>
  );
};

export default LightSettings;
