import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

const ProtectedRoute = ({ ...routeProps }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) return <Route {...routeProps} />;

  return <Redirect to="/login" />;
};

export default ProtectedRoute;
