import React, { useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Dialog,
  Paper,
  Link,
  FormControl,
  FormControlLabel,
  TextField,
  InputLabel,
  Box,
  Button,
  Switch,
} from '@material-ui/core';

import { HuePicker } from 'react-color';
import NumberInput from 'src/components/NumberInput';

import LightSettingsContext from './context';

import {
  defaultColor,
  generateCommand,
  dispatchScanToLight,
} from 'src/utils/scan';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  wrapper: {
    margin: theme.spacing(2),
    minWidth: 600,
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },

  formControl: {
    display: 'block',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  colorFieldWrap: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
  },
  numberInput: {
    width: `calc((100% - ${theme.spacing(1)}px) / 2)`,
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CatchAllListItem = ({ item, children }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const { updateCatchAlls } = useContext(LightSettingsContext);

  const [formData, setFormData] = useState(
    item || {
      sort: '',
      isLightOn: false,
      start: '',
      size: '',
      color: defaultColor,
    },
  );

  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  // FIXME: Reuse from reacklayoutedit component
  const simulateScan = useCallback((bin) => {
    if (!bin.isLightOn) return dispatchScanToLight('< 0, 0, 0, 0, 0 >');

    if (!bin.color || !bin.size || !bin.start) return;

    return dispatchScanToLight(generateCommand(bin));
  }, []);

  const update = useCallback(
    (payload) => {
      setFormData(payload);
      simulateScan(payload);
    },
    [simulateScan, setFormData],
  );

  const handleChange = (event) => {
    update({ ...formData, [event.target.name]: event.target.value });
  };

  const handleToggleChange = (event) => {
    update({ ...formData, [event.target.value]: event.target.checked });
  };

  const handleColorChange = (color) => {
    update({ ...formData, color: color.rgb });
  };

  const handleSubmit = () => {
    const entry = { ...formData };
    updateCatchAlls(entry);
    close();
  };

  return (
    <>
      <Grid item xs={2} className={classes.item}>
        <Link className={classes.link} onClick={open}>
          <Paper variant="outlined" className={classes.paper}>
            {children}
          </Paper>
        </Link>
      </Grid>

      <Dialog open={isOpen} maxWidth={false}>
        <div className={classes.wrapper}>
          <Box p={3}>
            <FormControl className={classes.formControl}>
              <TextField
                label="Sort"
                type="text"
                name="sort"
                margin="none"
                fullWidth
                autoComplete="false"
                autoCorrect="false"
                placeholder="Use * for wildcard. Example: SAL* to match any sort starting with SAL"
                value={formData.sort}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                variant="outlined"
                size="small"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                label="Lights"
                control={
                  <Switch
                    checked={formData.isLightOn}
                    onChange={handleToggleChange}
                    value="isLightOn"
                    inputProps={{ 'aria-label': 'light checkbox' }}
                  />
                }
              />
            </FormControl>
            {formData.isLightOn && (
              <div>
                <FormControl className={classes.formControl}>
                  <NumberInput
                    label="Start"
                    type="number"
                    margin="none"
                    name="start"
                    autoComplete="off"
                    autoCorrect="off"
                    value={formData.start}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 1,
                    }}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    className={classes.numberInput}
                  />
                  {/* TODO: Carve out this as a sepaerate component */}
                  <NumberInput
                    label="Length"
                    type="number"
                    margin="none"
                    name="size"
                    autoComplete="off"
                    autoCorrect="off"
                    value={formData.size}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 1,
                    }}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    className={classes.numberInput}
                  />
                </FormControl>
                <FormControl
                  align="center"
                  className={classes.formControl}
                  variant="outlined"
                >
                  <InputLabel htmlFor="component-outlined">
                    LED Color
                  </InputLabel>
                  <div
                    className={classes.colorFieldWrap}
                    id="component-outlined"
                  >
                    <HuePicker
                      color={formData.color}
                      onChangeComplete={handleColorChange}
                    />
                  </div>
                </FormControl>
              </div>
            )}
          </Box>
          <Box p={3} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={close}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </div>
      </Dialog>
    </>
  );
};

export default CatchAllListItem;
