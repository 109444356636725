import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Card, CardContent, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Page from '../../components/Page';

const useStyles = makeStyles({
  root: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    padding: 24,
  },
  datetime: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    width: 1,
    height: '80%',
    background: `linear-gradient(
      to bottom, 
      rgba(0, 0, 0, 0) 0%, 
      rgba(0, 0, 0, 0.12) 30%, 
      rgba(0, 0, 0, 0.12) 70%, 
      rgba(0, 0, 0, 0)
    )`,
  },
  typo: {
    padding: 24,
    textAlign: 'center',
  },
});

const DashboardView = (props) => {
  const classes = useStyles();

  return (
    <Page title="Dashboard">
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Link to="/scan" underline="none" component={RouterLink}>
            <Card variant="outlined">
              <CardContent className={classes.typo}>
                <Typography>PRIMARY SCAN</Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </Page>
  );
};

export default DashboardView;
