import React from 'react';
import Script from 'react-load-script';
import env from 'src/env';

import useAuth from 'src/hooks/useAuth';
const FeedbackWidget = () => {
  const { user } = useAuth();
  const token = env('USERBACK_API_KEY', false);
  if (!token) return null;

  if (!window.Userback) window.Userback = {};
  window.Userback.name = user ? user.firstname : '';
  window.Userback.access_token = token;

  return (
    <Script
      attributes={{ defer: true }}
      url="https://static.userback.io/widget/v1.js"
    />
  );
};

export default FeedbackWidget;
