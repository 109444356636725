export const dispatchScanToLight = (command) => {
  const event = new CustomEvent('scan-to-light', { detail: { command } });
  console.info('DISPATCHING COMMAND:', command);
  window.dispatchEvent(event);
};

export const generateCommand = (bin) =>
  `< ${bin.color.r}, ${bin.color.g}, ${bin.color.b}, ${bin.start -
    1}, ${bin.size - 1} >`;

export const defaultColor = { r: 0, g: 255, b: 0 };
