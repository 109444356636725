import { useState, useEffect } from 'react';
import api from 'src/services/api';

const useAPI = (request) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);

  const fetchData = async (params) => {
    setLoading(true);
    setError(null);

    try {
      const result = await request(api, params);
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({});
    // eslint-disable-next-line
  }, []);

  const refetch = () => {
    // passing params to clear cache.
    fetchData({ _t: +new Date() });
  };

  return {
    error,
    loading,
    data,
    refetch,
  };
};

export default useAPI;
