import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Dialog, Paper, Link } from '@material-ui/core';

import AddEditRack from './AddEditRack';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.background.primary,
  },
  wrapper: {
    margin: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const RackListItem = ({ rack, children }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Grid item xs={2} className={classes.item}>
        <Link className={classes.link} onClick={open}>
          <Paper variant="outlined" className={classes.paper}>
            {children}
          </Paper>
        </Link>
      </Grid>

      <Dialog maxWidth={false} fullWidth={true} open={isOpen}>
        <div className={classes.wrapper}>
          <AddEditRack rack={rack} onClose={close} />
        </div>
      </Dialog>
    </>
  );
};

export default RackListItem;
