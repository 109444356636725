import React from 'react';
import { styled } from '@material-ui/styles';
import ActivityIndicator from 'src/components/ActivityIndicator';

const Root = styled('div')({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

const LoadingState = () => {
  return (
    <Root>
      <ActivityIndicator size={64} thickness={2} />
    </Root>
  );
};

export default LoadingState;
