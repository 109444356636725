import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import useAPI from 'src/hooks/useAPI';
import LoadingState from 'src/components/LoadingState';
import ErrorState from 'src/components/ErrorState';

const useStyles = makeStyles({
  container: {
    height: 440,
  },
  table: {
    minWidth: 650,
  },
});

// TODO: Pagination & search
const ShipmentList = ({ customerId }) => {
  const classes = useStyles();

  const { error, loading, data } = useAPI((api) =>
    api.customers.items(customerId),
  );

  if (loading) return <LoadingState />;

  if (error) return <ErrorState subtitle={error.message} />;

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table
        className={classes.table}
        stickyHeader
        aria-label="shipment item list"
      >
        <TableHead>
          <TableRow>
            <TableCell>UPC / Barcode</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Units per pack</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((item, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {item.upc}
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell align="right">{item.unitsPerPack}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ShipmentList;
