import isPlainObject from 'lodash/isPlainObject';
import { createPathInterpolator } from './path';

/**
 * Create an API method from the declared spec.
 *
 * @param [spec.method='GET'] Request Method (POST, GET, PATCH, PUT, DELETE)
 * @param [spec.path=''] Path to be appended to the API BASE_PATH, joined with
 *  the instance's path (e.g. 'calendars' or 'users')
 * @param [spec.host] Hostname for the request.
 * @param [spec.validator] Allows request data to be validated before sending.
 * @param [spec.transformResponse] Allows changes to the response data to
 *  be made before it is passed to then/catch
 */
export default function method(spec) {
  return function() {
    return this.request(getRequestOpts.call(this, arguments, spec));
  };
}

function getRequestOpts(args, spec) {
  // Extract spec values with defaults.
  const commandPath =
    typeof spec.path === 'function'
      ? spec.path
      : createPathInterpolator(spec.path || '');
  const requestMethod = (spec.method || 'GET').toUpperCase();
  const host = spec.host;

  // Don't mutate args externally.
  args = [].slice.call(args);

  // Generate and validate url params.
  const urlData = {};

  commandPath.params.forEach((param) => {
    // Note that we shift the args array after every iteration so this just
    // grabs the "next" argument for use as a URL parameter.
    const arg = args[0];

    if (!arg) {
      if (param.optional) {
        urlData[param] = '';
        return;
      }

      const path = this.createResourcePathWithSymbols(spec.path);
      throw new Error(
        `API: Argument "${param.name}" required, but got: ${arg}` +
          ` (on API request to \`${requestMethod} ${path}\`)`,
      );
    }

    urlData[param.name] = args.shift();
  });

  // Pull request data and options (headers) from args.
  const data = spec.transformData
    ? spec.transformData(getDataFromArgs(args))
    : getDataFromArgs(args);
  const options = getOptionsFromArgs(args);

  // Validate that there are no more args.
  if (args.length) {
    const path = this.createResourcePathWithSymbols(spec.path);
    throw new Error(
      `API: Unknown arguments (${args}).` +
        ` Did you mean to pass an options object?` +
        ` (on API request to \`${requestMethod} ${path}\`)`,
    );
  }

  const requestPath = this.createFullPath(commandPath, urlData);
  const headers = Object.assign(options.headers, spec.headers);

  if (spec.validator) {
    spec.validator(data, { headers });
  }

  const params = /POST|PUT|PATCH/i.test(requestMethod) ? null : data;

  return {
    method: requestMethod,
    path: requestPath,
    data,
    params,
    headers,
    host,
    transformResponse: spec.transformResponse,
  };
}

/**
 * Return the data argument from a list of arguments
 */
export const getDataFromArgs = (args) => {
  if (args.length > 0 && isPlainObject(args[0])) {
    return args.shift();
  }

  return {};
};

/**
 * Return the options hash from a list of arguments
 */
export const getOptionsFromArgs = (args) => {
  const opts = {
    headers: {},
  };

  return opts;
};

export const create = method({
  method: 'POST',
});

export const list = method({
  method: 'GET',
});

export const get = method({
  method: 'GET',
  path: ':id',
});

export const update = method({
  method: 'PATCH',
  path: ':id',
});

// Avoid `delete` keyword in JS
export const del = method({
  method: 'DELETE',
  path: ':id',
});

export const BASIC_METHODS = {
  get,
  list,
  create,
  update,
  delete: del,
};

export function include(...methods) {
  methods.forEach((methodName) => {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      const methodNames = Object.keys(BASIC_METHODS);
      if (!methodNames.includes(methodName)) {
        throw new SyntaxError(
          `"${methodName}" is not a pre-defined basic method.` +
            ` Available pre-defined methods include: ${methodNames}`,
        );
      }

      if (methodName in this) {
        throw new Error(`"${methodName}" is already defined on ${this.name}`);
      }
    }

    this[methodName] = BASIC_METHODS[methodName];
  });
}

method.include = include;
