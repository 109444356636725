import React from 'react';
import { Typography } from '@material-ui/core';
import RackListItem from './RackListItem';

const AddRackButton = () => {
  return (
    <RackListItem>
      <Typography>+</Typography>
    </RackListItem>
  );
};

export default AddRackButton;
