import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import LoadingState from '../LoadingState';
import DocumentTitle from './DocumentTitle';
import Header from './Header';
import Sidebar from './Sidebar';
import { makeStyles } from '@material-ui/core';

const Main = styled('main')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: 'calc(100vh - 64)',
  padding: 4,
  marginTop: 64,
  boxSizing: 'border-box',
});

const useStyles = makeStyles({
  addedMargin: {
    padding: 12,
    marginLeft: 64,
  },
});

const Page = ({ actions, children, loading, title, ...props }) => {
  const classes = useStyles();
  if (loading) {
    return <LoadingState />;
  }

  const isScanPage = title.toLowerCase().includes('scan');

  return (
    <DocumentTitle title={title}>
      <Header isScanPage={isScanPage} />
      <Main className={isScanPage ? '' : classes.addedMargin}>{children}</Main>
      {!isScanPage && <Sidebar />}
    </DocumentTitle>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Page;
