import Resource from '../Resource';

class Auth extends Resource {
  static path = '/auth';

  token = Resource.method({
    method: 'POST',
    path: '/token',
  });
}

export default Auth;
