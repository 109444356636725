import pathToRegexp from 'path-to-regexp';

/**
 * Join all arguments together and normalize the resulting url path
 */
export const join = (...paths) => {
  return (
    paths
      .filter((path) => {
        if (typeof path !== 'string') {
          throw new TypeError(`Path must be a string. Received ${typeof path}`);
        }

        return path.length;
      })
      .join('/')
      // The following regex replaces consecutive `/`s unless they are preceded by a protocol.
      .replace(/(https?:\/\/)|(\/)+/g, '$1$2')
  );
};

/**
 * Outputs a new function with interpolated object property values.
 * Use like so:
 *   const fn = createPathInterpolator('some/url/:param1/:param2');
 *   fn({ param1: 123, param2: 456 }); // => 'some/url/123/456'
 */
export const createPathInterpolator = (str) => {
  const params = [];
  pathToRegexp(str, params);

  const toPath = pathToRegexp.compile(str);
  toPath.params = params;

  return toPath;
};
const path = { createPathInterpolator, join };

export default path;
