import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import FormField from '../../components/FormField';

const validationSchema = yup.object({
  username: yup
    .string()
    .ensure()
    .required('Username is required'),
  password: yup
    .string()
    .ensure()
    .trim()
    .min(6)
    .max(128)
    .required('Password is required'),
});

const initialValues = validationSchema.default();

const useStyles = makeStyles((theme) => ({
  error: {
    margin: '10px 0',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

const LoginForm = (props) => {
  const classes = useStyles();
  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ status }) => (
        <Form>
          {props.error && (
            <Typography
              variant="subtitle1"
              color="error"
              className={classes.error}
            >
              {props.error}
            </Typography>
          )}
          <FormField
            className={classes.field}
            type="text"
            name="username"
            label="Username"
          />
          <FormField
            className={classes.field}
            type="password"
            name="password"
            label="Password"
          />
          <Button
            type="submit"
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={props.loading}
            size="large"
            fullWidth
          >
            Sign in
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
